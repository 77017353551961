<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row class="d-flex justify-content-end">
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button class="mr-2" variant="primary" @click="fetchData">
                <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                <span class="text-nowrap">Refresh</span>
              </b-button>

              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.tambah-Kampus variant="primary">
                <feather-icon icon="PlusCircleIcon" class="mr-50" />
                <span class="text-nowrap">Tambah Kampus</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- modal tambah kategori Tryout-->
      <b-modal id="tambah-Kampus" cancel-variant="outline-secondary" ok-title="Tambah" cancel-title="Cancel" centered size="lg" title="Tambah Kampus" @show="resetModal" @hidden="resetModal" @ok="submitKampus">
        <b-form>
          <b-overlay :show="isSubmitting" rounded="sm">
            <b-form-group>
              <label for="nama">Nama Kampus</label>
              <b-form-input for="nama" v-model.trim="form.nama" type="text" placeholder="Nama Kampus" />
            </b-form-group>
          </b-overlay>
        </b-form>
      </b-modal>

      <!-- modal edit kampus-->
      <b-modal id="edit-kampus" cancel-variant="outline-secondary" ok-title="Edit" cancel-title="Cancel" centered title="Edit Kampus" @ok="editKampus(listKampus[currentKampusIndex].id)">
        <b-form>
          <b-overlay :show="isSubmitting" rounded="sm">
            <b-form-group>
              <label for="nama">Nama Kampus</label>
              <b-form-input for="nama" v-model.trim="form.nama" type="text" placeholder="Nama Kampus" />
            </b-form-group>
          </b-overlay>
        </b-form>
      </b-modal>

      <!-- modal hapus kampus-->
      <b-modal id="hapus-kampus" cancel-variant="outline-secondary" ok-variant="danger" ok-title="Hapus" cancel-title="Cancel" centered title="Hapus" @ok="hapusKampus(listKampus[currentKampusIndex].id)">
        <b-form>
          <b-overlay :show="isSubmitting" rounded="sm">
            <b-form-group>
              <p>Apakah anda yakin ingin menghapus?</p>
            </b-form-group>
          </b-overlay>
        </b-form>
      </b-modal>

      <!-- MODAL LIST KAMPUS -->
      <b-table :busy="isLoading" class="position-relative" :items="listKampus" responsive :fields="tableColumns" primary-key="id" show-empty striped hover empty-text="Tidak ada data ditemukan">
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Sedang memuat data...</strong>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item @click="show(data.item.id)">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Lihat Jurusan</span>
            </b-dropdown-item>

            <b-dropdown-item @click="setRowIndex(data.item)" v-b-modal.edit-kampus>
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="setRowIndex(data.item)" v-b-modal.hapus-kampus>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <!-- MODAL LIST KAMPUS -->
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BModal,
  VBModal,
  BForm,
  BFormGroup,
  BCardText,
  BButton,
  BTable,
  BListGroupItem,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
// import store from "@/store";
import { ref, onMounted } from "@vue/composition-api";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import draggable from "vuedraggable";
import vSelect from "vue-select";
import { useRouter } from "@core/utils/utils";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import repository from "@repofactory";
const repoKampus = repository.get("KampusRepository");

export default {
  components: {
    // ModalTambah,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BModal,
    BFormCheckbox,
    VBModal,
    BForm,
    BFormGroup,
    BCardText,
    BDropdown,
    flatPickr,
    BDropdownItem,
    ToastificationContent,
    BCardActions,
    draggable,
    BListGroupItem,
    vSelect,
    BOverlay,
    DatePicker,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  setup() {
    const toast = useToast();
    const { router } = useRouter();
    const isLoading = ref(true);
    const showLihat = ref(false);
    const isSubmitting = ref(false);
    const isError = ref(false);
    const currentKampusIndex = ref(null);
    const form = ref({
      nama: "",
    });

    const listKampus = ref([]);

    const tableColumns = [
      {
        key: "id",
        label: "ID",
        sortable: true,
      },
      {
        key: "nama",
        label: "Nama Kampus",
        sortable: true,
      },

      {
        key: "actions",
      },
    ];

    onMounted(async () => {
      fetchData();
    });

    const setForm = () => {
      form.value.nama = listKampus.value[currentKampusIndex.value].nama;
    };

    const setRowIndex = (item) => {
      currentKampusIndex.value = listKampus.value.findIndex(
        (kampus) => kampus.id == item.id
      );

      setForm();
    };

    const showToast = (title, text, variant, icon = "BellIcon") => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    };

    const resetModal = () => {
      form.value = {
        nama: "",
      };
    };

    const fetchData = async () => {
      isLoading.value = true;

      await repoKampus
        .get()
        .then(function (response) {
          isLoading.value = false;
          listKampus.value = response.data.data;
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isLoading.value = false;
          isError.value = true;
        });
    };

    const show = async (id) => {
      router.push({ name: "manajemen-jurusan", params: { id: id } });
      // await repoPaketTryout
      //   .show(id)
      //   .then(function (response) {
      //     form.value.paket_kategoris = response.data.data.paket_kategoris;
      //     showLihat.value = true;
      //   })
      //   .catch(function (error) {
      //     if (error.response) {
      //       showToast(
      //         "Error",
      //         error.response.data.message,
      //         "danger",
      //         "AlertTriangleIcon"
      //       );
      //     } else if (error.request) {
      //       showToast(
      //         "Error",
      //         "Tidak bisa request data ke server",
      //         "danger",
      //         "AlertTriangleIcon"
      //       );
      //     } else {
      //       showToast("Error", error.message, "danger", "AlertTriangleIcon");
      //     }
      //     isError.value = true;
      //   });
    };

    const submitKampus = async () => {
      isSubmitting.value = true;

      const data = {
        nama: form.value.nama,
      };

      await repoKampus
        .create(data)
        .then((response) => {
          listKampus.value.push(response.data.data);

          showToast("Notifikasi", response.data.message, "primary");

          isSubmitting.value = false;
          resetModal();
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
        });
    };

    const editKampus = async (id) => {
      isSubmitting.value = true;

      const data = {
        nama: form.value.nama,
      };

      await repoKampus
        .update(data, id)
        .then((response) => {
          listKampus.value[currentKampusIndex.value].nama =
            response.data.data.nama;

          showToast("Notifikasi", response.data.message, "primary");

          isSubmitting.value = false;
          resetModal();
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
        });
    };

    const hapusKampus = async (id) => {
      isSubmitting.value = true;

      await repoKampus
        .delete(id)
        .then((response) => {
          listKampus.value.splice(currentKampusIndex.value, 1);
          showToast("Notifikasi", response.data.message, "primary");

          isSubmitting.value = false;
          resetModal();
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
        });
    };

    return {
      isError,
      isLoading,
      isSubmitting,
      tableColumns,
      form,
      listKampus,
      currentKampusIndex,

      // methods
      setRowIndex,
      fetchData,
      resetModal,
      submitKampus,
      editKampus,
      hapusKampus,

      show,
      showLihat,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
